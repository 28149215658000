import React from "react"
import Layout from "../components/layout"
import { Col, Container, Image, Row } from "react-bootstrap"
import { BsArrowReturnLeft } from "react-icons/bs"
import dustbin from "../../public/static/images/products/dustbin.jpg"

function Dustbin() {

  return (
    <Layout className="semi-automatic">
      <section className="pt-5 pb-4" style={{ backgroundColor: "white" }}>
        <Container>
          <Row>
            <Col md={4}>
              <Image src={dustbin} fluid />
            </Col>

            {/* Name */}

            {/* Detail */}
            <Col md={8}>
            <h2 className="heading">DUSTBIN</h2>
              <p>As one of the leading entity of this domain, we are engrossed in providing supreme quality Dust Bin. The offered bin finds wide application use in hotels, offices, restaurants, malls, parks and other similar places for keeping the environment neat and clean. This bin is manufactured using quality materials and available in all different sizes to meet the requirements. Apart from this, clients can get the bin from us at reasonable rates.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Dustbin
